<template>
  <b-container fluid>
    <b-row v-if="subscriptionPlanObjList && subscriptionPlanObjList.length > 0">
      <div class="col-12">
        <div class="iq-card position-relative inner-page-bg bg-primary cpheading">
          <div class="inner-page-title">
            <h3 class="text-white choose">
              Choose Your Plan
            </h3>
          </div>
        </div>
      </div>
      <div class="row subPlanRow" deck  style="width: 100%;margin-left: 0; margin-right: 0; justify-content:left;" >
        <b-col sm="6" md="4" lg="4" v-for="(subsPlan, index2) in subscriptionPlanObjList" :key="subsPlan.subs_plan_id+subsPlan[index2]" >
          <iq-card body-class="text-center" :class="subsPlan.active ? 'bg-primary text-white' : ''">
            <template v-slot:body>
              <span class="font-size-16 text-uppercase" v-if="subsPlan.plan !== ''">{{ subsPlan.subs_plan_name }}</span>
              <h2 class="mb-4 font-weight-bolder" :class="subsPlan.active ? 'text-white' : ''">
                {{subsPlan.currency_symbol}} {{ subsPlan.subs_plan_price }}
                <small class="font-size-14 " :class="subsPlan.active ? 'text-white' : 'text-muted'">/{{subsPlan.subs_plan_interval.replace('ly', '')}}</small>
              </h2>
              <!-- <li class="list-unstyled">{{subsPlan.subs_plan_desc}}</li> -->
              <div class="col-md-12 modules text-left pl-4" data-toggle="collapse" v-b-toggle="'collapse' + index2" >
                <p class="moduleHeading m-0" >Details
                  <span>
                    <i class="fa fa-chevron-circle-down"></i>
                    <i class="fa fa-chevron-circle-up"></i>
                  </span>
                </p>
              </div>
              <b-collapse v-bind:id="'collapse' + index2" class="mt-0">
                <div class="col-md-12 moduleslists" >
                  <ul>
                    <li v-for="(subsModule, indexM) in utility.splitTextBy(subsPlan.subs_plan_desc, '.')" :key="indexM+'B'">
                      {{ subsModule }}
                    </li>
                  </ul>
                </div>
              </b-collapse>
              <!-- <ul class="list-unstyled line-height-4 mb-0 moduleList" >
              <li>{{subsPlan.subs_plan_desc}}</li>
              <li v-for="(subsModule, indexM) in getSubsModules(subsPlan)" :key="indexM+'B'">{{ subsModule }}</li>
            </ul> -->
              <b-button :variant="subsPlan.active ? 'light' : 'primary'" class="mt-4 " :class="subsPlan.buttonClass" @click="goToPaymentGateway(subsPlan)">
                {{ ($route.query.module_name == 'admission') ? 'Make Payment' : 'Buy Now' }}
              </b-button>
            <!-- <b-button :variant="subsPlan.active ? 'light' : 'primary'" class="ml-3 mt-5" :class="subsPlan.buttonClass" @click="showPlanDetail(subsPlan)">View</b-button> -->
            </template>
          </iq-card>
        </b-col>
      </div>
    </b-row>

    <b-row v-else style="text-align:center;">
      <div class="col-12">
        No Plans Found
      </div>
    </b-row>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
    <!-- Subscription Confirmation Popup -->
    <SubscriptionConfirmation :propShowSCModal="propShowSCModal" :propSubsPlanDetails="propSubsPlanDetails" @emitCloseSCModal="emitCloseSCModal" />
    <!-- PlanDetail -->
    <PlanDetail :propShowPDModal="propShowPDModal" :propSubsPlanDetails="propSubsPlanDetails" @emitClosePDModal="emitClosePDModal" />
  </b-container>
</template>

<style lang="scss" scoped>
.choose{
  padding: 30px;
}
  .cpheading{
    min-height: 100px;
    font-size: 13px;
  }
  .inner-page-title {
    position: unset !important;
  }
  @media (max-width: 479px) {
    .cpheading{
      font-size: 12px;
    }
  }
</style>

<script>

import { SubscriptionPlans } from "../../../FackApi/api/Subscription/subscriptionPlan"
import { socialvue } from "../../../config/pluginInit"
import SubscriptionConfirmation from "../../../components/SubscriptionConfirmation.vue"
import PlanDetail from "../../../components/PlanDetail.vue"
import Utility from "../../../Utils/utility.js"

export default {
  name: "SubsPlanList",
  components: {
    SubscriptionConfirmation,
    PlanDetail
  },
  data () {
    return {
      apiName: "subscriptionPlan_list",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Subscription Plans",
      subscriptionPlanObjList: [],
      subscriptionPlanChunkObj: {},
      subsModules: {},
      propShowSCModal: false,
      propSubsPlanDetails: {},
      propShowPDModal: false,
      selectedModuleForPlan: null,
      utility: Utility
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  mounted () {
    this.loadRequiredData()
  },
  watch: {
    async userData () {
      let userData = await this.userData
      console.log(userData)
      this.loadRequiredData()
    }
  },
  methods: {
    /**
     * loadRequiredData
     */
    async loadRequiredData () {
      socialvue.index()
      this.selectedModuleForPlan = await this.$store.getters["Paygw/selectedModuleForPlanState"]
      if (this.$route.query.type === "custom" && this.selectedModuleForPlan) {
        this.subscriptionPlanCustomList(this.selectedModuleForPlan)
      }
      else {
        this.subscriptionPlanList()
      }
    },
    /**
     * subscriptionPlanList
     */
    async subscriptionPlanList () {
      try {
        let payload = { currency_code: "INR" }
        if (this.userData.country_name && this.userData.country_name.toLowerCase() !== "india") {
          payload.currency_code = "USD"
        }

        if (this.$route.query.module_name) {
          payload.module_name = this.$route.query.module_name
        }

        if (this.userData.user_role && this.userData.user_role == "USERROLE11116") {
          payload.subs_plan_allowed_role = this.userData.user_role
        }

        let subscriptionPlanListResp = null
        if (this.$route.fullPath.includes("addOns")) {
          subscriptionPlanListResp = await SubscriptionPlans.subscriptionAddOnPlanList(this, payload)
        }
        else {
          subscriptionPlanListResp = await SubscriptionPlans.subscriptionPlanList(this, payload)
        }

        if (subscriptionPlanListResp.resp_status) {
          this.subscriptionPlanObjList = subscriptionPlanListResp.resp_data.data
          this.totalRows = subscriptionPlanListResp.resp_data.count
        }
        else {
          this.toastMsg = subscriptionPlanListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at subscriptionPlanList() and Exception:", err.message)
      }
    },
    /**
     * subscriptionPlanCustomList
     */
    async subscriptionPlanCustomList (checkObj) {
      try {
        let subscriptionPlanListResp = await SubscriptionPlans.subscriptionPlanCustomList(this, { mod_name: checkObj.module, interval_type: "oneTime" })
        if (subscriptionPlanListResp.resp_status) {
          this.subscriptionPlanObjList = subscriptionPlanListResp.resp_data.data
          this.totalRows = subscriptionPlanListResp.resp_data.count
        }
        else {
          this.toastMsg = subscriptionPlanListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at subscriptionPlanList() and Exception:", err.message)
      }
    },
    /**
     * showConfirmPlan
     */
    showConfirmPlan (subsPlan) {
      this.propShowSCModal = Math.random()
      this.propSubsPlanDetails = subsPlan
    },
    /**
     * goToPaymentGateway
     */
    async goToPaymentGateway (subsPlan) {
      await this.$store.commit("Paygw/addSelectedPlanCommit", subsPlan)
      this.$router.push("/pay_gw")
    },
    /**
     * emitCloseSCModal
     */
    emitCloseSCModal () {
      this.propShowSCModal = false
      this.propSubsPlanDetails = {}
    },
    /**
     * showConfirmPlan
    */
    async showPlanDetail (plan) {
      this.propShowPDModal = Math.random()
      this.propSubsPlanDetails = plan
    },
    /**
     * emitCloseSCModal
     */
    emitClosePDModal () {
      this.propShowSCModal = false
      this.propSubsPlanDetails = {}
    }
  }
}
</script>
